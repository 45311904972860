export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/(root)": [6,[2]],
		"/app": [10,[3]],
		"/app/admin": [11,[3,4]],
		"/app/admin/leaderboard": [12,[3,4]],
		"/app/admin/mission": [13,[3,4]],
		"/app/admin/result": [14,[3,4]],
		"/app/admin/team": [15,[3,4]],
		"/app/admin/user": [16,[3,4]],
		"/app/team": [17,[3]],
		"/auth": [18],
		"/(root)/hall-of-fame": [7,[2]],
		"/overlay/leaderboard": [19,[5]],
		"/overlay/mission": [20,[5]],
		"/(root)/rules": [8,[2]],
		"/(root)/teams": [9,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';